/* .calendar-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
  background-color: #f0f0f0;
}

.month-container {
  margin-right: 15px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.month-title {
  text-align: center;
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
}

.days-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.day-box {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333333;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.day-box:hover {
  background-color: #ddd;
}

.day-box.solved {
  background-color: #32cd32;
  color: #ffffff;
} */

.calendar-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: white;
}

.month-container {
  margin-right: 10px;
}

.month-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 5px; /* Adjust as needed */
}

.days-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.day-box {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 1px;
  color: black;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border-radius: 10%;
  border: 1px solid #ccc; /* Add border with light gray color */
}

.day-box.solved {
  background-color: green;
}
